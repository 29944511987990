
<template>
  <div class="test">
    <p>{{time}}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: "",
    }
  },
  methods: {
    huoquTime(string) {
      var f = string.split(' ', 2);
      var Ymd = (f[0] ? f[0] : null).split('-', 3);
      var His = (f[1] ? f[1] : null).split(':', 3);
      return (new Date(
        parseInt(Ymd[0], 10) || null,
        parseInt(Ymd[1], 10) - 1 || null,
        parseInt(Ymd[2], 10) || null,
        parseInt(His[0], 10) || null,
        parseInt(His[1], 10) || null,
        parseInt(His[2], 10) || null
      )).getTime()
    }
  },
  mounted() {
    var date = new Date().getTime();
    var aDate = this.huoquTime('2022-03-10 18:00:00');
    this.time = Math.round((aDate - date) / 1000);
    this.currentTimer = setInterval(() => {
      this.time--;
    }, 1000);
  }
}
</script>

<style>
.test {
  width: 500px;
  height: 500px;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  line-height: 500px;
  margin: 0 auto;
}
</style>